import React from 'react';
import { ButtonAuto } from '../../Button';

import Styled from './Style';

export const RedButtons = ({
  leftButtonLabel,
  leftButtonLink,
  rightButtonLabel,
  rightButtonLink,
}) => {
  if (
    leftButtonLabel &&
    leftButtonLink &&
    rightButtonLabel &&
    rightButtonLink
  ) {
    return (
      <Styled.RedButtonWrapper>
        <ButtonAuto className="margin-right" linkText={leftButtonLink}>
          {leftButtonLabel}
        </ButtonAuto>
        <ButtonAuto linkText={rightButtonLink}>{rightButtonLabel}</ButtonAuto>
      </Styled.RedButtonWrapper>
    );
  } else if (leftButtonLabel && leftButtonLink) {
    return (
      <Styled.RedButtonWrapper>
        <ButtonAuto linkText={leftButtonLink}>{leftButtonLabel}</ButtonAuto>
      </Styled.RedButtonWrapper>
    );
  } else if (rightButtonLabel && rightButtonLink) {
    return (
      <Styled.RedButtonWrapper>
        <ButtonAuto linkText={rightButtonLink}>{rightButtonLabel}</ButtonAuto>
      </Styled.RedButtonWrapper>
    );
  }
  return null;
};
