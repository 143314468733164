import React from 'react';
import ContentPageLink from '@jetshop/ui/ContentPageLink';
import { css } from 'linaria';
import { theme } from '../../../Theme';

function FooterLinks(props) {
  const allLinks = props ? addRouteToLinks(props.data) : null;

  const navLink = css`
    .footer-link {
      ${theme.below.xl} {
        font-size: 1rem;
        line-height: 1.4rem;
        margin-bottom: 1.25rem;
      }
      ${theme.above.xl} {
        font-size: 0.875rem;
        line-height: 1.225rem;
        margin-bottom: 0.75rem;
      }
      display: block;
      color: ${theme.colors.sumPrimaryBlack};
      text-decoration: none;
      :hover {
        color: ${theme.colors.sumPrimaryRed};
      }
    }
  `;

  function addRouteToLinks(links) {
    for (let link of links) {
      link.internalLinkUrl = { primaryRoute: { path: link.internalLinkUrl } };
    }
    return links;
  }

  return allLinks ? (
    <ul>
      {allLinks.map((link, index) => (
        <li key={link.linkTitle + index} className={navLink}>
          <ContentPageLink page={link.internalLinkUrl} className="footer-link">
            {link.linkTitle}
          </ContentPageLink>
        </li>
      ))}
    </ul>
  ) : null;
}

export default FooterLinks;
