import React from 'react';
import Styled from './Style';

export const RedBanners = (props) => {
  const { bannerLabel } = props;

  if (!bannerLabel) return null;

  return (
    <Styled.RedBannerWrapper className="red-banner">
      <Styled.RedBanner>{bannerLabel}</Styled.RedBanner>
    </Styled.RedBannerWrapper>
  );
};
