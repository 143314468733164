import React from 'react';
import cleanProps from '../scripts/cleanProps';
import RichText from '../RichText';
import { ImageWithAspectRatio } from '../ContentItems/ImageWithAspectRatio';
import { styled } from 'linaria/react';
import { Above } from '@jetshop/ui/Breakpoints';
import MaxWidth from '../../Layout-old/MaxWidth';

const BlockWrapperSmall = styled(MaxWidth)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const BlockWrapperLarge = styled(MaxWidth)`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
  box-sizing: border-box;
`;

const HalfWidth = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
`;

const TextAndImageLarge = ({ html, leftRight, alt, aspectRatio, image }) => {
  if (leftRight === 'left') {
    return (
      <BlockWrapperLarge removeMobilePadding={true} removeDesktopPadding={true}>
        <HalfWidth>
          <ImageWithAspectRatio src={image} aspect={aspectRatio} alt={alt} />
        </HalfWidth>
        <HalfWidth>
          <TextWrapper>
            <RichText html={html} />
          </TextWrapper>
        </HalfWidth>
      </BlockWrapperLarge>
    );
  }

  return (
    <BlockWrapperLarge removeMobilePadding={true} removeDesktopPadding={true}>
      <HalfWidth>
        <TextWrapper>
          <RichText html={html} />
        </TextWrapper>
      </HalfWidth>
      <HalfWidth>
        <ImageWithAspectRatio src={image} aspect={aspectRatio} alt={alt} />
      </HalfWidth>
    </BlockWrapperLarge>
  );
};

const TextAndImageSmall = ({ html, alt, aspectRatio, image }) => (
  <BlockWrapperSmall removeMobilePadding={true} removeDesktopPadding={true}>
    <ImageWithAspectRatio src={image} aspect={aspectRatio} alt={alt} />
    <TextWrapper>
      <RichText html={html} />
    </TextWrapper>
  </BlockWrapperSmall>
);

const TextAndImage = (props) => {
  if (!props.children || props.children.length < 1) {
    console.error('TextAndImage is missing imageWithAspectRatio!', props);
    return null;
  }
  const imageWithAspectRatioProps = props.children[0];
  if (!imageWithAspectRatioProps.props) {
    console.error(
      'TextAndImage - imageWithAspectRatio is missing props!',
      props
    );
    return null;
  }

  const { html, leftRight } = cleanProps(props);
  const { alt, aspectRatio, image } = cleanProps(
    imageWithAspectRatioProps.props
  );

  const options = {
    html,
    leftRight,
    alt,
    aspectRatio,
    image,
  };

  return (
    <Above breakpoint="lg">
      {(matches) =>
        matches ? (
          <TextAndImageLarge {...options} />
        ) : (
          <TextAndImageSmall {...options} />
        )
      }
    </Above>
  );
};

export default TextAndImage;
