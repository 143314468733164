import React from 'react';
import { styled } from 'linaria/react';
import LinkItem from '../ContentItems/LinkItem';
import MaxWidth from '../../Layout-old/MaxWidth';
import useChildren from '../hooks/useChildren';
import { ButtonAuto } from '../Button';

const ButtonsWrapper = styled(MaxWidth)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const Buttons = (props) => {
  const allProps = useChildren(props);

  const links = allProps[LinkItem];

  const Button = styled(ButtonAuto)`
    margin-right: 1rem;
    margin-bottom: 1rem;
  `;

  return (
    <ButtonsWrapper>
      {links.map((link) => (
        <Button linkText={link.href}>{link.label}</Button>
      ))}
    </ButtonsWrapper>
  );
};

export default Buttons;
