import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import imageLink from '../ContentItems/imageLink';
import { ImageWithAspectRatio } from '../ContentItems/ImageWithAspectRatio';
import MaxWidth from '../../Layout-old/MaxWidth';
import useChildren from '../hooks/useChildren';
import { AreaAuto } from '../Button';

const CardGridWrapper = styled(MaxWidth)`
  margin-bottom: 2rem;

  ${theme.above.md} {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    gap: 1rem;
  }
`;

const Card = styled(AreaAuto)`
  color: ${theme.colors.black};
  border: 1px solid ${theme.colors.sumSecondaryGray};
  box-sizing: border-box;
  height: 10rem;

  :hover {
    border-color: ${theme.colors.red};
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${theme.below.md} {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const SizeWrapper = styled('div')`
  width: 3rem;
  height: 3rem;
`;

const AltText = styled('div')`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 2px;
`;

const CardGrid = (props) => {
  const allProps = useChildren(props);

  const imageLinks = allProps[imageLink];

  return (
    <CardGridWrapper>
      {imageLinks.map((imageLinkProps) => {
        const { image, href, alt, key } = imageLinkProps;
        return (
          <Card linkText={href} key={key}>
            <SizeWrapper>
              <ImageWithAspectRatio
                src={image}
                alt={alt}
                aspect="1:1"
                sizes={[48]}
              />
            </SizeWrapper>
            <AltText>{alt}</AltText>
          </Card>
        );
      })}
    </CardGridWrapper>
  );
};

export default CardGrid;
