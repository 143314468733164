import React from 'react';
import Image from '@jetshop/ui/Image';
import cleanProps from '../scripts/cleanProps';
import { motion } from 'framer-motion';

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const ImageTitleTextItem = (props) => {
  const propsCleaned = cleanProps(props);

  const { image, title, BlockWrapper, ImageWrapper, TextWrapper } =
    propsCleaned;

  return (
    <motion.div variants={item}>
      <BlockWrapper>
        <ImageWrapper>
          <Image src={image} />
        </ImageWrapper>
        <TextWrapper>{title}</TextWrapper>
      </BlockWrapper>
    </motion.div>
  );
};

export default ImageTitleTextItem;
