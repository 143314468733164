import { styled } from 'linaria/react';
import { theme } from '../Theme';

const MaxWidth = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  padding: ${(props) => (props.removeMobilePadding ? '0' : '0 0.75rem')};
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: ${(props) =>
    props.removeDesktopPadding
      ? theme.maxWidths.wide
      : theme.maxWidths.default};
  ${theme.above.sm} {
    padding: ${(props) => (props.removeDesktopPadding ? '0' : '0 2rem')};
  }
`;

export default MaxWidth;
