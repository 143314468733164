import React from 'react';
import { styled } from 'linaria/react';

import { ReactComponent as FacebookLogo } from '../../../../svg/facebook.svg';
import { ReactComponent as InstagramLogo } from '../../../../svg/instagram.svg';
import { ReactComponent as PinterestLogo } from '../../../../svg/pinterest.svg';
import { ReactComponent as LinkedinLogo } from '../../../../svg/linkedin.svg';
import { ReactComponent as YoutubeLogo } from '../../../../svg/youtube.svg';

import { theme } from '../../../Theme';

const Wrapper = styled('div')`
  margin-right: 0;
  ul {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  li {
    margin: 0 0.5rem 0 0;
  }

  a.social-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: ${theme.colors.sumPrimaryBlack};
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
  }

  a.social-links svg {
    fill: ${theme.colors.white};
    width: 1rem;
    height: 1rem;
  }

  color: ${theme.colors.black};
`;

const LogoWrapper = styled('span')`
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  svg {
    width: 24px;
    fill: '${theme.colors.black}';
  }
  svg:hover {
    stroke: '${theme.colors.red}';
  }
`;

const SocialLinks = (props) => {
  const socialMediaLinks = props?.data;

  function getIconByType(type) {
    switch (type) {
      case 'Facebook':
        return <FacebookLogo />;
      case 'Instagram':
        return <InstagramLogo />;
      case 'Pinterest':
        return <PinterestLogo />;
      case 'Linkedin':
        return <LinkedinLogo />;
      case 'Youtube':
        return <YoutubeLogo />;
      default:
        return '';
    }
  }

  return (
    <Wrapper>
      {socialMediaLinks ? (
        <ul>
          {socialMediaLinks.map((link, index) => (
            <li key={index}>
              <a
                className="social-links"
                aria-label={link.linkTitle}
                href={link.externalLinkUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LogoWrapper>{getIconByType(link.linkTitle)}</LogoWrapper>
              </a>
            </li>
          ))}
        </ul>
      ) : (
        ''
      )}
    </Wrapper>
  );
};

export default SocialLinks;
