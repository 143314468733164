import React from 'react';
import { Link } from 'react-router-dom';
import cleanProps from '../scripts/cleanProps';

const ImageButtonItem = (props) => {
  const propsCleaned = cleanProps(props);

  const { image, alt, to } = propsCleaned;

  return (
    <Link to={to}>
      <img src={image} alt={alt} />
    </Link>
  );
};

export default ImageButtonItem;
