import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import ImageTitleItem from '../ContentItems/ImageTitleItem';
import { theme } from '../../Theme';
import { motion } from 'framer-motion';

const bannerWithImageTitleItemsBlockWrapper = css`
  width: 100%;
  background-color: transparent;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${theme.below.md} {
    display: none;
  }
`;

const BlockWrapper = styled('div')`
  margin-right: 1.75rem;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ImageWrapper = styled('div')`
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.3125rem;
`;

const TextWrapper = styled('div')`
  color: white;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 5%;
  font-weight: bold;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const BannerWithImageTitleItemsBlock = (props) => {
  if (!props.children) return null;

  const elements = [];
  for (let i = 0; i < props.children.length; i += 1) {
    const imageAndTextItem = props.children[i];
    elements.push(
      <ImageTitleItem
        key={imageAndTextItem.key}
        {...imageAndTextItem.props}
        BlockWrapper={BlockWrapper}
        ImageWrapper={ImageWrapper}
        TextWrapper={TextWrapper}
      />
    );
  }

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className={bannerWithImageTitleItemsBlockWrapper}
    >
      {elements}
    </motion.div>
  );
};

export default BannerWithImageTitleItemsBlock;
