import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import CategoryComponents from '../ui/CategoryComponents';
import shopConfig from '../../shop.config';
import Instagram from './Instagram';
import SiteContentContext from '../SiteContent/SiteContentContext';
import TitleBlock from '../ui/ContentBlocks/TitleBlock';

const FooterWrapper = styled('footer')`
  /* This is just so that you can find the footer easilly when you inspect! */
  .insta-feed-text {
    max-width: 700px;
    margin: 0rem auto 2rem;
    text-align: center;
  }
`;

const Footer = () => {
  const { instaFeedTitle, instaFeedText } = useContext(SiteContentContext);
  return (
    <FooterWrapper>
      <TitleBlock title={instaFeedTitle} />
      <p className="insta-feed-text">{instaFeedText}</p>
      <Instagram />
      <CategoryComponents categoryPageId={shopConfig.categoryId.footer} />
    </FooterWrapper>
  );
};

export default Footer;
