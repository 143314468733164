import React from 'react';
import cleanProps from '../scripts/cleanProps';

const ParagraphItem = (props) => {
  const propsCleaned = cleanProps(props);

  const { text } = propsCleaned;

  return <p>{text}</p>;
};

export default ParagraphItem;
