import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';

const AbsoluteContainer = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  &.not-in-mobile {
    ${theme.below.md} {
      position: static;
      width: 100%;
      .red-banner > * {
        width: 100%;
      }
    }
  }
`;

export default AbsoluteContainer;
