import React from 'react';
import { Below, Above } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import Carousel from 'my-react-carousel';

import { ReactComponent as IconArrow } from '../../svg/caret-2.svg';
import { theme } from '../Theme';

const Container = styled.div`
  position: relative;
  width: 100%;

  & > div > div {
    padding: 20px 0;
  }
`;

const ItemContainer = styled('div')``;

const SlidesIndexWrapper = styled('div')`
  margin: 0 0.5rem;
  font-size: 1rem;
  color: white;
`;

const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
  border-radius: 100%;

  &[data-direction='prev'] {
    left: -50px;
    transform: translateY(-50%) rotate(180deg);
    color: ${(props) => props.color};
    background: transparent;
  }
  &[data-direction='next'] {
    right: -50px;
    color: ${(props) => props.color};
    background: transparent;
  }
`;

function CarouselComponent({ children }) {
  return (
    <Below breakpoint="sm">
      {(matches) => (matches ? children(2) : children(4))}
    </Below>
  );
}

const Main = ({
  children,
  ProductCard,
  centerCurrentSlide = true,
  infinite = true,
}) => {
  const carouselRef = React.useRef(null);
  return (
    <CarouselComponent>
      {(slides) => (
        <Container>
          <Carousel
            ref={carouselRef}
            slidesToShow={slides}
            infinite={infinite}
            centerCurrentSlide={centerCurrentSlide}
            render={({
              slides,
              hasPrevious,
              previous,
              hasNext,
              next,
              currentStep,
              totalSteps,
            }) => (
              <>
                {slides}

                <Above breakpoint="md">
                  {(matches) =>
                    matches ? (
                      <>
                        <Arrow
                          onClick={previous}
                          data-direction="prev"
                          background={
                            hasPrevious
                              ? theme.colors.sumPrimaryRed
                              : theme.colors.sumSecondaryDarkGray
                          }
                          color={theme.colors.white}
                        >
                          <IconArrow />
                        </Arrow>
                        {children && children.length > 1 ? (
                          <SlidesIndexWrapper>
                            {currentStep + 1} / {totalSteps}
                          </SlidesIndexWrapper>
                        ) : null}
                        <Arrow
                          onClick={next}
                          data-direction="next"
                          background={
                            hasNext
                              ? theme.colors.sumPrimaryRed
                              : theme.colors.sumSecondaryDarkGray
                          }
                          color={theme.colors.white}
                        >
                          <IconArrow />
                        </Arrow>
                      </>
                    ) : null
                  }
                </Above>
              </>
            )}
          >
            {children.map((product, index) => {
              return (
                <ItemContainer key={index + ':' + product.articleNumber}>
                  <ProductCard
                    product={product}
                    className="slider-product"
                    as="div"
                  />
                </ItemContainer>
              );
            })}
          </Carousel>
        </Container>
      )}
    </CarouselComponent>
  );
};
export default Main;
