import React from 'react';
import { Image } from '../../Image';
import AbsoluteContainer from '../../AbsoluteContainer';
import { GridSize } from './GridSize';
import { CenterTitle } from './CenterTitle';
import { RedButtons } from './RedButtons';
import { RedBanners } from './RedBanner';
import Styled from './Style';

const PromotionalItem = ({
  image,
  imageFormat,
  alt,
  linkImage,
  centerTitle,
  leftButtonLabel,
  leftButtonLink,
  rightButtonLabel,
  rightButtonLink,
  bannerLabel,
}) => (
  <GridSize imageFormat={imageFormat}>
    <Styled.ClickArea linkText={linkImage}>
      <CenterTitle centerTitle={centerTitle} />
      <Image src={image} alt={alt} imageFormat={imageFormat} />
      <AbsoluteContainer className="not-in-mobile">
        <Styled.PromotionalItemWrapper>
          <RedButtons
            leftButtonLabel={leftButtonLabel}
            leftButtonLink={leftButtonLink}
            rightButtonLabel={rightButtonLabel}
            rightButtonLink={rightButtonLink}
          />
          <RedBanners bannerLabel={bannerLabel} />
        </Styled.PromotionalItemWrapper>
      </AbsoluteContainer>
    </Styled.ClickArea>
  </GridSize>
);

export default PromotionalItem;
