import React from 'react';
import { css } from 'linaria';
import ImageButtonItem from '../ContentItems/ImageButtonItem';
import { motion } from 'framer-motion';

const centeredImageBlockWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
`;

const CenteredImageBlock = (props) => {
  if (!props.children) return null;

  const buttonImageItem = props.children[0];

  return (
    <motion.section
      className={centeredImageBlockWrapper}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
    >
      <ImageButtonItem key={buttonImageItem.key} {...buttonImageItem.props} />
    </motion.section>
  );
};

export default CenteredImageBlock;
