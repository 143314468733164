import React from 'react';
import { styled } from 'linaria/react';
import cleanProps from '../scripts/cleanProps';
import MaxWidth from '../../Layout-old/MaxWidth';
import RichText from '../../ui/RichText';
import { ButtonExternal } from '../../ui/Button';
import { theme } from '../../Theme';
import { ReactComponent as CogwheelsLeft } from '../../../svg/cogwheels-left.svg';
import { ReactComponent as CogwheelsRight } from '../../../svg/cogwheels-right.svg';

const FormWrapper = styled(MaxWidth)`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  background-color: ${theme.colors.sumSecondaryLightGray};
  position: relative;
  overflow: hidden;

  ${theme.above.xl} {
    padding-left: 25rem;
    padding-right: 25rem;
  }
  ${theme.below.xl} {
    ${theme.above.lg} {
      padding-left: 20rem;
      padding-right: 20rem;
    }
    ${theme.below.lg} {
      ${theme.above.md} {
        padding-left: 15rem;
        padding-right: 15rem;
      }
      ${theme.below.md} {
        ${theme.above.sm} {
          padding-left: 10rem;
          padding-right: 10rem;
        }
        ${theme.below.sm} {
          padding-left: 4rem;
          padding-right: 4rem;
        }
      }
    }
  }

  /*
  xs: '20rem',
  sm: '40rem',
  md: '50rem',
  lg: '64rem',
  xl: '80rem',
  */
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 1;
`;

const CogwheelsLeftStyled = styled(CogwheelsLeft)`
  position: absolute;
  left: 0px;
  bottom: 0px;
`;

const CogwheelsRightStyled = styled(CogwheelsRight)`
  position: absolute;
  right: -117px;
  bottom: 0px;
`;

const Form = (props) => {
  const propsCleaned = cleanProps(props);

  const { html, formspreeURL, sendButtonLabel } = propsCleaned;

  return (
    <FormWrapper removeDesktopPadding={true}>
      <CogwheelsLeftStyled />
      <CogwheelsRightStyled />
      <RichText html={html} />
      <ButtonWrapper>
        <ButtonExternal href={formspreeURL}>{sendButtonLabel}</ButtonExternal>
      </ButtonWrapper>
    </FormWrapper>
  );
};

export default Form;
