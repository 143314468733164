const loadScriptFile = (appendToRef, src = '', id = null) => {
  // <appendToRef><script src={src} id={id} /></appendToRef>
  // A script tag with a src and id is added to the tag appendToRef
  // This returns a Promise

  if (!appendToRef) {
    return Promise.reject('Missing DOM reference in loadScriptFile!');
  }

  return new Promise((resolve, reject) => {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.src = src;
    scriptTag.async = true;
    scriptTag.onload = resolve;
    scriptTag.onerror = reject;
    scriptTag.id = id;
    appendToRef.appendChild(scriptTag);
  });
};

export default loadScriptFile;
