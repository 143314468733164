import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import { AreaAuto } from '../../Button';

const FullscreenWrapper = styled('section')`
  position: relative;
  grid-column-start: span 2;
`;

const HalfScreenWrapper = styled('section')`
  position: relative;
  overflow: hidden;

  [data-flight-image-container] {
    position: relative;
    transition: all ease 1s;
    &::before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      background: rgba(0, 0, 0, 0);
      transition: background ease 0.5s;
    }
  }
  &:hover {
    [data-flight-image-container] {
      transform: scale(1.1);
      transition: all ease 0.5s;
      &::before {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  &[data-square='false'] {
    ${theme.below.md} {
      grid-column-start: span 2;
    }
  }
`;

const RedButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  & > .margin-right {
    margin-right: 40px;
  }
`;

const Text = styled('div')`
  color: white;
  margin-bottom: 40px;
  font-size: 32px;
  line-height: 46px;
  letter-spacing: 0.05em;
  text-align: center;
`;

const PromotionalItemWrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const ClickArea = styled(AreaAuto)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const RedBannerWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

const RedBanner = styled('div')`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  text-align: center;
  font-weight: bold;

  padding-left: 30px;
  padding-right: 30px;
  padding-top: 4px;
  padding-bottom: 4px;

  color: ${theme.colors.white};
  background-color: ${theme.colors.sumPrimaryRed};
`;

const style = {
  FullscreenWrapper,
  HalfScreenWrapper,
  RedButtonWrapper,
  Text,
  PromotionalItemWrapper,
  ClickArea,
  RedBannerWrapper,
  RedBanner,
};

export default style;
