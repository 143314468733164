import React from 'react';
import Styled from './Style';

export const CenterTitle = (props) => {
  const { centerTitle } = props;

  if (!centerTitle) return null;

  return <Styled.Text>{centerTitle}</Styled.Text>;
};
