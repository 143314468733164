import loadScriptFile from './loadScriptFile';

export const initFlowbox = () => {
  // Use the google link first since they seem to write new articles about this
  // https://www.google.com/search?q=Embedding+a+Flowbox+Flow+on+your+website&oq=Embedding+a+Flowbox+Flow+on+your+website
  // https://help.getflowbox.com/en/articles/2550100-embedding-a-flowbox-flow-on-your-website
  // Test Flowbox with this Flow Key: He369uUnThamaSvwdyRfVQ

  /*
  (function(d, id) {
    if (!window.flowbox) { var f = function () { f.q.push(arguments); }; f.q = []; window.flowbox = f; }
    if (d.getElementById(id)) {return;}
    var s = d.createElement('script'), fjs = d.scripts[d.scripts.length - 1]; s.id = id; s.async = true;
    s.src = ' https://connect.getflowbox.com/flowbox.js';
    fjs.parentNode.insertBefore(s, fjs);
  })(document, 'flowbox-js-embed');
  */

  return new Promise((resolve, reject) => {
    if (!window.flowbox) {
      var f = function () {
        f.q.push(arguments);
      };
      f.q = [];
      window.flowbox = f;
    }

    if (document.getElementById('flowbox-js-embed') !== null) {
      resolve(
        'The flow initiation is already complete or in progress, skipping!'
      );
      return;
    }

    loadScriptFile(
      document.head,
      'https://connect.getflowbox.com/flowbox.js',
      'flowbox-js-embed'
    )
      .then(() => {
        resolve('Flowbox initiated!');
      })
      .catch((message) => {
        reject("Flowbox didn't initiate!", message);
      });
  });
};

export const initFlow = (flowId, flowKey, flowLocale) => {
  // Load a flow with flowKey into the tag with flowId
  // Select what language to get flows from with flowLocale, sv-SE, en-US

  window.flowbox('init', {
    container: `#${flowId}`,
    key: flowKey,
    locale: flowLocale,
  });
};
