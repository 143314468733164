import React, { memo, useState } from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { StyledCarrot } from '@jetshop/ui/Select/Components';
import t from '@jetshop/intl';
import { theme } from '../../../Theming/Theming';
import { useLocation } from 'react-router';
import deepCategoriesQuery from '../Categories/DeepCategoriesQuery.gql';
import { Query } from 'react-apollo';
import { CleanCategories } from '../Categories/CategoryProvider';

const CategoryWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  .subcategories {
    margin-bottom: 0.5rem;
  }
`;

const CategoryButton = styled('button')`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;
  padding: 0 ${theme.mobileHeader.nav.padding};
  border-bottom: 1px solid ${theme.colors.borderLight};
  background: ${theme.colors.white};
  ${StyledCarrot} {
    margin-right: 0px;
  }
  text-overflow: ellipsis;
  white-space: nowrap;
  &[data-level='1'] {
    font-family: ${theme.mobileHeader.nav.lv1.fontFamily};
    font-weight: ${theme.mobileHeader.nav.lv1.fontWeight};
    font-size: ${theme.mobileHeader.nav.lv1.fontSize};
    line-height: ${theme.mobileHeader.nav.lv1.lineHeight};
    border-bottom: 2px solid ${theme.colors.white};
    //background: ${theme.colors.greys[1]};
    text-transform: ${theme.mobileHeader.nav.lv1.textTransform};
  }
  &[data-level='2'] {
    font-size: ${theme.mobileHeader.nav.lv2.fontSize};
    line-height: ${theme.mobileHeader.nav.lv2.lineHeight};
  }
  &[data-level='3'] {
    font-size: ${theme.mobileHeader.nav.lv3.fontSize};
    line-height: ${theme.mobileHeader.nav.lv3.lineHeight};
    border-left: ${theme.mobileHeader.nav.padding} solid #e0e0e0;
    padding-left: 1.5rem;
  }
  &.active,
  &.active > a {
    color: ${theme.mobileHeader.nav.activeColor};
  }
  &.selected,
  &.selected > a {
    color: ${theme.mobileHeader.nav.selectedColor};
  }
`;

const CategoryHasSubs = styled(CategoryLink)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

function inPath(category, pathname) {
  let currentPath = pathname;
  let returnObject = {
    inPath: false,
    exactPath: false,
  };

  if (
    category.primaryRoute &&
    currentPath.search(category.primaryRoute.path) !== -1
  ) {
    returnObject.inPath = true;
    returnObject.exactPath = currentPath === category.primaryRoute.path;
  }
  return returnObject;
}

const Category = memo(
  ({
    children,
    category,
    defaultOpen = false,
    level,
    hideTarget,
    activeCategory,
    setActiveCategory,
  }) => {
    const hasSubs = category.subcategories && category.subcategories.length > 0;
    const location = useLocation();
    let classNames = [];
    const po = inPath(category, location.pathname);

    if (po.inPath) {
      defaultOpen = true;
      classNames.push('active');
    }

    if (po.exactPath || activeCategory === category.id)
      classNames.push('selected');

    const [isOpen, setOpen] = useState(defaultOpen);
    return (
      <CategoryWrapper>
        <CategoryButton data-level={level} className={classNames.join(' ')}>
          {hasSubs ? (
            <CategoryHasSubs
              onClick={(e) => {
                e.preventDefault();
                setOpen(!isOpen);
              }}
              category={category}
            >
              {category.name}
              <StyledCarrot className={isOpen ? 'open' : ''} />
            </CategoryHasSubs>
          ) : (
            <CategoryLink
              category={category}
              onClick={(e) => {
                hideTarget();
                setActiveCategory(category.id);
                if (category?.primaryRoute?.path?.search('http') !== -1) {
                  e.preventDefault();
                  window.location = category.primaryRoute.path;
                }
              }}
            >
              {category.name}
            </CategoryLink>
          )}
        </CategoryButton>
        {isOpen && hasSubs && (
          <div className="subcategories">
            <CategoryButton data-level={level + 1}>
              <CategoryLink
                category={category}
                onClick={() => {
                  hideTarget();
                  setActiveCategory(category.id);
                }}
              >
                {t('Show all')}
              </CategoryLink>
            </CategoryButton>
            {children}
          </div>
        )}
      </CategoryWrapper>
    );
  }
);

const CategoryTree = ({
  parent = null,
  categories,
  level = 1,
  hideTarget,
  activeCategory,
  setActiveCategory,
}) => {
  if (categories && categories.length > 0) {
    return categories.map((category) => (
      <Category
        category={category}
        key={category.id}
        level={level}
        hideTarget={hideTarget}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      >
        <CategoryTree
          parent={category}
          categories={category.subcategories}
          level={level + 1}
          hideTarget={hideTarget}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
      </Category>
    ));
  } else return null;
};

const MobileCategories = ({ categories, hideTarget, isOpen }) => {
  const [activeCategory, setActiveCategory] = useState('trail');
  const [open, setOpen] = useState(false);
  if (isOpen && !open) setOpen(true);
  if (open)
    return (
      <Query variables={{ levels: 1 }} query={deepCategoriesQuery}>
        {(result) => {
          const { data } = result;
          const fullCategories =
            (data && CleanCategories(data.categories)) || categories;
          if (categories) {
            return (
              <CategoryTree
                categories={fullCategories}
                hideTarget={hideTarget}
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
              />
            );
          } else return null;
        }}
      </Query>
    );
  if (categories && categories.length > 0) {
    return (
      <CategoryTree
        categories={categories}
        hideTarget={hideTarget}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
    );
  } else return null;
};

export default MobileCategories;
