import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import SearchProduct from './SearchProduct';
import SearchFlyoutCategories from './SearchFlyoutCategories';
import { theme } from '../../../Theming/Theming';
import { ButtonUI } from '../../../ui/ButtonUI';
import CloseButton from '../../../ui/CloseButton';

export const SuggestedFlyout = styled('div')`
  position: absolute;
  right: 0;
  top: ${theme.mobileHeader.height.sm};
  background: white;
  padding: 1rem;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.4);
  z-index: 1;
  overflow: auto;
  &:not(.full) {
    max-width: 700px;
    max-height: 400px;
    ${theme.above.lg} {
      top: 44px;
      width: 500px;
    }
  }
  .close-flyout-search {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 1rem;
    margin: -1rem;
  }
  ${theme.below.lg} {
    left: 0;
  }
  &[data-full-view='true'] {
    ${theme.above.lg} {
      position: fixed;
      width: 100%;
      max-width: none;
      padding-bottom: 2rem;
      top: ${theme.topBar.height.lg + theme.desktopHeader.height.lg}px;
      max-height: calc(
        100vh - ${theme.topBar.height.lg + theme.desktopHeader.height.lg}px
      );
      &[data-scroll='true'] {
        top: ${theme.desktopHeader.height.scroll}px;
        max-height: calc(100vh - ${theme.desktopHeader.height.scroll}px);
      }
      display: flex;
      .flyout-products {
        width: 100%;
        > ul {
          display: flex;
          flex-wrap: wrap;
        }
        .search-product {
          width: 25%;
          > a {
            flex-direction: column;
          }
          .search-product-image {
            width: 100%;
          }
          .search-product-details {
            width: 100%;
          }
        }
      }
      .flyout-categories {
        width: 25%;
        .search-category {
          > a > div {
            flex-direction: column;
            align-items: flex-start;
            span {
              margin: 0;
            }
          }
        }
      }
    }
  }
`;

const SearchFlyoutProducts = styled('div')``;

export const FlyoutTitle = styled('h4')`
  border-bottom: 1px solid ${theme.colors.borderLight};
  margin-bottom: 0.5rem !important;
  padding-bottom: 0.3rem;
  color: ${theme.colors.greys[4]};
`;

export const NoResultTitle = styled('h5')`
  color: ${theme.colors.greys[2]};
  margin-bottom: 2rem;
`;

const ProductList = styled('ul')``;

const SearchAllWrap = styled('div')`
  padding: 2rem 0;
  button {
    max-width: 320px;
    margin: 0 auto;
    display: block;
  }
`;

const SearchFlyout = (searchProps) => {
  const products =
    (searchProps.result &&
      searchProps.result.products &&
      searchProps.result.products.result) ||
    [];
  const fullView = true;
  return (
    <FlyoutTarget id="searchFlyout" data-flight-searchfield-flyout={true}>
      {(flyoutProps) => {
        return (
          flyoutProps.isOpen &&
          searchProps.isDirty && (
            <div {...searchProps.getFlyoutProps()}>
              <SuggestedFlyout
                data-full-view={fullView}
                data-scroll={searchProps.scrolling}
              >
                <CloseButton
                  className="close-flyout-search"
                  onClick={searchProps.closeSearch}
                />
                <SearchFlyoutCategories
                  className="flyout-categories"
                  searchProps={searchProps}
                  hideTarget={flyoutProps.hideTarget}
                />
                <SearchFlyoutProducts className="flyout-products">
                  <FlyoutTitle>{t('Products')}</FlyoutTitle>
                  {searchProps.loading ? (
                    t('Loading…')
                  ) : products.length > 0 ? (
                    <Fragment>
                      <ProductList>
                        {products.map((product) => (
                          <SearchProduct
                            fullView={fullView}
                            key={product.id}
                            product={product}
                            onClose={() => {
                              searchProps.closeSearch();
                              flyoutProps.hideTarget();
                            }}
                            term={searchProps.term}
                          />
                        ))}
                      </ProductList>
                      {products.length !== 24 && (
                        <SearchAllWrap>
                          <ButtonUI onClick={() => searchProps.triggerSearch()}>
                            {t('View all')}
                          </ButtonUI>
                        </SearchAllWrap>
                      )}
                    </Fragment>
                  ) : (
                    <NoResultTitle>{t('No results')}</NoResultTitle>
                  )}
                </SearchFlyoutProducts>
              </SuggestedFlyout>
            </div>
          )
        );
      }}
    </FlyoutTarget>
  );
};

export default SearchFlyout;
