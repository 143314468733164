import { addwishTracker } from '@jetshop/flight-addwish';
import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/Categories/HomeCategoriesQuery.gql';
import routeQuery from './components/RouteQuery.gql';
import ProductPage from './components/ProductPage/ProductPage.loadable';
import ContentPage from './components/ContentPage/ContentPage.loadable';
import {
  LoadableStandardCategoryPage,
  LoadableWindowedCategoryPage,
} from './components/CategoryPage/CategoryPage';
import { theme } from './components/Theme';

const config = {
  theme,
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'stjarnurmakarna',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io/',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: homeCategoriesQuery,
        variables: { levels: 1 },
      },
    ],
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID,
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
  relewareEnabled: true,
  sentry: {
    clientDSN: process.env.FLIGHT_SENTRY_CLIENT_DSN,
    serverDSN: process.env.FLIGHT_SENTRY_SERVER_DSN,
    ignoreErrors: [],
  },
  intl: {
    translations,
    defaultLocale: 'en',
    options: {},
  },
  channelOverrides: {},
  disableGeoRedirect: process.env.REACT_APP_DISABLE_GEOREDIRECT || true,
  singleDomainMode: process.env.REACT_APP_SINGLE_DOMAIN_MODE,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false,
  },
  openGraph: {
    disableDefaultProductData: false,
  },
  trackers: [addwishTracker('27FF0210AF94C37D4855CDDE01F268B8')],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: [
    '/login',
    '/reset-password',
    '/forgot-password',
    '/signup',
    '/my-pages',
  ],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [ProductPage],
      Category: [LoadableStandardCategoryPage, LoadableWindowedCategoryPage],
      Page: [ContentPage],
    },
  },
  categoryId: {
    header: 242,
    footer: 243,
  },
  routes: { stores: { path: '/klockbutiker' } },
  useTrackingConsentAPI: true,
};
export default config;
