import React from 'react';
import FooterLinks from './FooterLinks';
import EqualSections from '../../EqualSections';
import { theme } from '../../../Theme';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

const FooterNavigation = (props) => {
  const { navLinks } = props;

  const informationLinks = navLinks.Information;
  const assortimentLinks = navLinks.Assortment;
  const brandLinks = navLinks.Brand;

  const LeftWrapper = styled('section')`
    ${theme.below.xl} {
      margin-bottom: 2.625rem;
    }
    ${theme.above.xl} {
      display: flex;
      justify-content: flex-start;
    }
  `;

  const CenterWrapper = styled('section')`
    ${theme.below.xl} {
      margin-bottom: 2.625rem;
    }
    ${theme.above.xl} {
      display: flex;
      justify-content: center;
    }
  `;

  const RightWrapper = styled('section')`
    ${theme.below.xl} {
      margin-bottom: 2.625rem;
    }
    ${theme.above.xl} {
      display: flex;
      justify-content: flex-end;
    }

    ul :last-child {
      a {
        color: ${theme.colors.red};
        text-decoration: underline;
        font-weight: bold;
        :hover {
          color: ${theme.colors.black};
        }
      }
    }
  `;

  const FooterLinksTitle = styled('h2')`
    font-size: 1rem;
    line-height: 1.75rem;
  `;

  return (
    <EqualSections>
      {informationLinks ? (
        <LeftWrapper>
          <section>
            <FooterLinksTitle>{t('Information')}</FooterLinksTitle>
            <FooterLinks data={informationLinks} />
          </section>
        </LeftWrapper>
      ) : (
        ''
      )}
      {assortimentLinks ? (
        <CenterWrapper>
          <section>
            <FooterLinksTitle>{t('Assortiment')}</FooterLinksTitle>
            <FooterLinks colorLast={true} data={assortimentLinks} />
          </section>
        </CenterWrapper>
      ) : (
        ''
      )}
      {brandLinks ? (
        <RightWrapper>
          <section>
            <FooterLinksTitle>{t('Brand')}</FooterLinksTitle>
            <FooterLinks data={brandLinks} />
          </section>
        </RightWrapper>
      ) : (
        ''
      )}
    </EqualSections>
  );
};

export default FooterNavigation;
