import React from 'react';
import { IMAGE_FORMATS } from '../../Image';
import Styled from './Style';

export const GridSize = (props) => {
  const { children, imageFormat } = props;

  if (imageFormat === IMAGE_FORMATS.ONE_WIDTH) {
    return <Styled.FullscreenWrapper>{children}</Styled.FullscreenWrapper>;
  }

  return (
    <Styled.HalfScreenWrapper
      data-square={imageFormat === IMAGE_FORMATS.SQUARE}
    >
      {children}
    </Styled.HalfScreenWrapper>
  );
};
