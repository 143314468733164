import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import cleanProps from '../scripts/cleanProps';

const Title = styled('h2')`
  margin-bottom: 12px;
`;

const Details = styled('p')``;

const ImageTitleTextItem = (props) => {
  const propsCleaned = cleanProps(props);

  const { image, text, title, BlockWrapper, TextWrapper, ImageWrapper } =
    propsCleaned;

  return (
    <BlockWrapper>
      <ImageWrapper>
        <Image src={image} />
      </ImageWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <Details>{text}</Details>
      </TextWrapper>
    </BlockWrapper>
  );
};

export default ImageTitleTextItem;
