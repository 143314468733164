import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import { Link } from 'react-router-dom';
import { Email, Phone, SocialMedia } from './Contact';
import Logo from '../../../../svg/customer/logo-footer.svg';

const AboutUsText = styled('div')`
  white-space: pre-line;
  color: ${theme.colors.sumPrimaryBlack};

  ${theme.below.xl} {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 1.875rem;
  }
  ${theme.above.xl} {
    font-size: 0.875rem;
    line-height: 1.225rem;
    margin-bottom: 1.25rem;
  }
`;

const VerticalLine = styled('section')`
  ${theme.above.xl} {
    height: 100%;
    margin-left: 3rem;
    margin-right: 3rem;
    border-left: 1px solid ${theme.colors.sumSecondaryLightGray};
  }
  ${theme.below.xl} {
    margin-bottom: 3.5rem;
  }
`;

const VerticalLineWrapper = styled('section')`
  flex-grow: 0;
`;

const UiLink = styled(Link)`
  color: ${theme.colors.sumPrimaryRed};
  :hover {
    color: ${theme.colors.sumPrimaryBlack};
  }
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.625rem;
`;

const FooterLogoSocialDescription = (props) => {
  const {
    descriptionTitle,
    descriptionText,
    linkLabel,
    linkUrl,
    phone,
    email,
    socialMediaLinks,
  } = props;

  const LogoContactWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;

  const DescriptionContentWrapper = styled('div')``;

  const FooterLogoSocialDescriptionWrapper = styled('section')`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    ${theme.below.xl} {
      flex-direction: column;
    }
  `;

  const Contact = styled('div')`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  `;

  const StyledLogo = styled('img')`
    ${theme.above.xl} {
      width: 10rem;
      margin-bottom: 6.25rem;
    }
    ${theme.below.xl} {
      width: 13.375rem;
      margin-bottom: 2rem;
    }
  `;

  const FooterLinksTitle = styled('h2')`
    font-size: 1rem;
    line-height: 1.75rem;
  `;

  const showLink = linkLabel && linkUrl ? true : false;

  return (
    <FooterLogoSocialDescriptionWrapper>
      <LogoContactWrapper>
        <StyledLogo src={Logo} alt="Stjärnurmakarnas logo" />
        <Contact>
          <Email email={email} />
          <Phone phone={phone} />
          <SocialMedia socialMediaLinks={socialMediaLinks} />
        </Contact>
      </LogoContactWrapper>

      <VerticalLineWrapper>
        <VerticalLine />
      </VerticalLineWrapper>

      <DescriptionContentWrapper>
        {descriptionTitle ? (
          <FooterLinksTitle>{descriptionTitle}</FooterLinksTitle>
        ) : (
          ''
        )}
        {descriptionText ? <AboutUsText>{descriptionText}</AboutUsText> : ''}
        {showLink && <UiLink to={linkUrl}>{linkLabel}</UiLink>}
      </DescriptionContentWrapper>
    </FooterLogoSocialDescriptionWrapper>
  );
};

export default FooterLogoSocialDescription;
