import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import MaxWidth from '../../Layout-old/MaxWidth';
import { ButtonLink } from '../Button';
import { theme } from '../../Theme';
import TextColor from '../ContentItems/TextColor';
import TitleItem from '../ContentItems/TitleItem';
import ButtonItem from '../ContentItems/ButtonItem';
import useChildren from '../hooks/useChildren';
import { Above } from '@jetshop/ui/Breakpoints';

const CategoryHeroWrapper = styled(MaxWidth)`
  position: relative;
  background-color: ${theme.colors.white};
  margin-bottom: 1rem;
`;

const Title = styled('span')`
  ${theme.fontStyles.heroTitle};
  text-align: center;
  color: ${(props) => {
    switch (props.textColor) {
      case 'red':
        return theme.colors.red;
      default:
        return props.textColor;
    }
  }};
  font-family: 'Brandon Text Web';
`;

const ImageWrapper = styled('div')``;

const Overlay = styled('div')`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  color: white;

  &.hero-overlay-both {
    > span {
      ${theme.above.lg} {
        margin-right: 1.25rem;
      }
      ${theme.below.lg} {
        margin-bottom: 1.6875rem;
      }
    }
  }
`;

const OverlayContents = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CategoryHeroOverlay = (props) => {
  const { title, buttonLabel, buttonTo, textColor } = props;

  if (title && buttonLabel && buttonTo) {
    return (
      <Overlay className="hero-overlay-both">
        <OverlayContents>
          <Title textColor={textColor}>{title}</Title>
          <ButtonLink to={buttonTo}>{buttonLabel}</ButtonLink>
        </OverlayContents>
      </Overlay>
    );
  } else if (title) {
    return (
      <Overlay className="hero-overlay-title">
        <Title textColor={textColor}>{title}</Title>
      </Overlay>
    );
  } else if (buttonLabel && buttonTo) {
    return (
      <Overlay className="hero-overlay-button">
        <ButtonLink to={buttonTo}>{buttonLabel}</ButtonLink>
      </Overlay>
    );
  }

  return null;
};

const CategoryHero = (props) => {
  const allProps = useChildren(props);

  const { image, alt } = allProps;

  const textColorProps = allProps[TextColor]?.pop() ?? {
    textColor: 'white',
  };
  const titleProps = allProps[TitleItem]?.pop() ?? {
    title: 'Title missing!',
  };
  const buttonProps = allProps[ButtonItem]?.pop() ?? {
    label: false,
    to: false,
  };

  return (
    <CategoryHeroWrapper removeMobilePadding={true} removeDesktopPadding={true}>
      <ImageWrapper>
        <Above breakpoint="md">
          {(matches) => (
            <Image
              src={image}
              aspect={matches ? '4:1' : '2:1'}
              alt={alt}
              crop={true}
              cover
            />
          )}
        </Above>
      </ImageWrapper>
      <CategoryHeroOverlay
        title={titleProps.title}
        textColor={textColorProps.textColor}
        buttonLabel={buttonProps.label}
        buttonTo={buttonProps.to}
      />
    </CategoryHeroWrapper>
  );
};

export default CategoryHero;
