import React from 'react';

const Instagram = () => {
  return (
    <div className="instagram">
      <script
        src="https://static.elfsight.com/platform/platform.js"
        data-use-service-core
        defer
      ></script>
      <div className="elfsight-app-1bd6871a-bb69-4744-8060-abe129ac9c7b"></div>
    </div>
  );
};

export default Instagram;
