import React from 'react';
import PromotionalItem from '../../ContentItems/PromotionalItem/PromotionalItem';
import cleanProps from '../../scripts/cleanProps';
import Styled from './Style';

const getPromotionalItems = (item) => (
  <PromotionalItem key={item.key} {...cleanProps(item.props)} />
);

const PromotionalBlock = (props) => {
  if (!props.children) return null;

  return (
    <Styled.PromotionalBlockWrapper
      removeMobilePadding={true}
      removeDesktopPadding={true}
    >
      {props.children.map(getPromotionalItems)}
    </Styled.PromotionalBlockWrapper>
  );
};

export default PromotionalBlock;
