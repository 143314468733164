import React from 'react';
import { styled } from 'linaria/react';
import CrossIcon from './icons/CrossIcon';

const SvgButtonWrapper = styled('button')`
  padding: 16px;
  margin: -16px;
  background: inherit;
  display: flex;
  svg {
    color: #444;
  }
`;

const DeleteButton = ({ onClick, className }) => (
  <SvgButtonWrapper
    onClick={onClick}
    className={className}
    aria-label="Close menu"
  >
    <CrossIcon />
  </SvgButtonWrapper>
);

export default DeleteButton;
