import { css } from 'linaria';

import PlayfairDisplayBlack from './Playfair_Display/PlayfairDisplay-Black.ttf';
import PlayfairDisplayBlackItalic from './Playfair_Display/PlayfairDisplay-BlackItalic.ttf';
import PlayfairDisplayBold from './Playfair_Display/PlayfairDisplay-Bold.ttf';
import PlayfairDisplayBoldItalic from './Playfair_Display/PlayfairDisplay-BoldItalic.ttf';
import PlayfairDisplayItalic from './Playfair_Display/PlayfairDisplay-Italic.ttf';
import PlayfairDisplayRegular from './Playfair_Display/PlayfairDisplay-Regular.ttf';

import BrandonTextWebBoldWoff2_v2 from './BrandonTextWeb_v2/Brandon_bld.woff2';
import BrandonTextWebBoldOTF_v2 from './BrandonTextWeb_v2/Brandon_bld.otf';
import BrandonTextWebRegularWoff2_v2 from './BrandonTextWeb_v2/Brandon_reg.woff2';
import BrandonTextWebRegularOTF_v2 from './BrandonTextWeb_v2/Brandon_reg.otf';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    :global(html) {
      /* Brandon Text Web, 100 000 views per month counter */
      /* @import url("//hello.myfonts.net/count/2fe8f3"); */

      /* Playfair Display - Regular */
      @font-face {
        font-family: 'Playfair Display';
        src: url(${PlayfairDisplayRegular}) format('truetype');
        font-weight: normal;
        font-weight: 400;
        font-style: normal;
      }

      /* Playfair Display - Bold */
      @font-face {
        font-family: 'Playfair Display';
        src: url(${PlayfairDisplayBold}) format('truetype');
        font-weight: bold;
        font-weight: 700;
        font-style: normal;
      }

      /* Playfair Display - Italic */
      @font-face {
        font-family: 'Playfair Display';
        src: url(${PlayfairDisplayItalic}) format('truetype');
        font-weight: normal;
        font-weight: 400;
        font-style: italic;
      }

      /* Playfair Display - Bold and Italic */
      @font-face {
        font-family: 'Playfair Display';
        src: url(${PlayfairDisplayBoldItalic}) format('truetype');
        font-weight: bold;
        font-weight: 700;
        font-style: italic;
      }

      /* Playfair Display Black - Regular */
      @font-face {
        font-family: 'Playfair Display Black';
        src: url(${PlayfairDisplayBlack}) format('truetype');
        font-weight: normal;
        font-weight: 400;
        font-style: normal;
      }

      /* Playfair Display Black - Italic */
      @font-face {
        font-family: 'Playfair Display Black';
        src: url(${PlayfairDisplayBlackItalic}) format('truetype');
        font-weight: normal;
        font-weight: 400;
        font-style: italic;
      }

      /* Brandon Text - Regular */
      @font-face {
        font-family: 'Brandon Text Web';
        src: url(${BrandonTextWebRegularWoff2_v2}) format('woff2'),
              url(${BrandonTextWebRegularOTF_v2}) format('opentype');
        font-weight: normal;
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      /* Brandon Text - Bold */
      @font-face {
        font-family: 'Brandon Text Web';
        src: url(${BrandonTextWebBoldWoff2_v2}) format('woff2'),
              url(${BrandonTextWebBoldOTF_v2}) format('opentype');
        font-weight: bold;
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }

      &.brandon-text-web-ready body {
        font-family: 'Brandon Text Web', sans-serif;
        letter-spacing: 0;
        word-spacing: 0;
        font-weight: normal;
      }
    }
  `;
}
