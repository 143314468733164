// import t from '@jetshop/intl';
import React from 'react';
import MaxWidth from '../../../Layout-old/MaxWidth';
import { theme } from '../../../Theme';
import { styled } from 'linaria/react';
import cleanProps from '../../scripts/cleanProps';

import FooterLogoSocialDescription from './FooterLogoSocialDescription';
import FooterNavigation from './FooterNavigation';

const ResponsiveWrapper = styled('div')`
  display: flex;
  margin-top: 3rem;

  h2 {
    padding: 0 0 0.875rem 0;
  }

  p {
    padding: 0.5rem 0;
  }

  ${theme.below.xl} {
    flex-direction: column;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  ${theme.above.xl} {
    margin-top: 3rem;
    margin-left: 4.1875rem;
    margin-right: 4.1875rem;
    margin-bottom: 3.75rem;
    justify-content: space-between;
    > * {
      flex: 10 0 0;
    }
  }
`;

// How much empty space there is between the two main footer parts
const CenterSpace = styled('div')`
  flex-grow: 1;
  margin: 2rem;
`;

const reduceChildrenToNavLinks = (data, link) => {
  const { type } = link;
  if (!type) return data;

  if (data[type]) {
    data[type] = link.props
      ? [...data[type], cleanProps(link.props)]
      : data[type];
    return data;
  }

  return { ...data, [type]: link.props ? [cleanProps(link?.props)] : [] };
};

const Footer = (props) => {
  if (!props.children) return null;

  const propsCleaned = cleanProps(props);

  const navLinks = propsCleaned?.children?.reduce(reduceChildrenToNavLinks, {});

  return (
    <section>
      <MaxWidth removeMobilePadding={true} removeDesktopPadding={true}>
        <ResponsiveWrapper>
          <FooterLogoSocialDescription
            descriptionTitle={propsCleaned.aboutUsTitle}
            descriptionText={propsCleaned.aboutUsDescription}
            linkLabel={propsCleaned.aboutLinkLabel}
            linkUrl={propsCleaned.aboutLinkUrl}
            phone={propsCleaned.phone}
            email={propsCleaned.email}
            socialMediaLinks={navLinks.SocialMedia}
          />
          <CenterSpace />
          <FooterNavigation navLinks={navLinks} />
        </ResponsiveWrapper>
      </MaxWidth>
    </section>
  );
};

export default Footer;
