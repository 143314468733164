import React from 'react';
import Image from '@jetshop/ui/Image/Image';

/* Use this when you have the exact parameters */
export const ImageWithAspectRatio = (props) => {
  const { src, alt, aspect, ...imageProps } = props;

  return <Image src={src} alt={alt} aspect={aspect} {...imageProps} />;
};

const ImageWithAspectRatioText = 'ImageWithAspectRatio';
export default ImageWithAspectRatioText;
