import React from 'react';
import useScript from '../../ui/hooks/useScript';

const TryggEhandel = () => {
  useScript(
    'https://cert.tryggehandel.net/js/script.js?id=250ea9d9-b251-4430-bba6-7bdd92fb389a'
  );

  return (
    <>
      <div className="teh-certificate" data-size="150" />
    </>
  );
};

export default TryggEhandel;
