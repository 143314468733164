import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import SiteContentContext from '../../../SiteContent/SiteContentContext';
import { useState } from 'react';
import { useEffect } from 'react';

const TopBarUspWrapper = styled('div')`
  &.top-bar-usp-list {
    > ul {
      > li {
        display: flex;
        align-items: center;
        font-size: ${theme.topBar.usp.fontSize};
        font-weight: ${theme.topBar.usp.fontWeight} !important;
        ${theme.only.sm} {
          font-size: 0.7rem !important;
        }
        img {
          margin-right: 5px;
          max-width: 20px;
        }
        svg {
          margin-right: 5px;
        }
        ${theme.only.xs} {
          &:first-child {
            padding-left: 0 !important;
          }
          &:last-child {
            padding-right: 0 !important;
          }
        }
        ${theme.only.lg} {
          display: none !important;
          &.lg,
          &.md,
          &.sm {
            display: flex !important;
          }
        }
        ${theme.below.md} {
          opacity: 0;
          position: absolute;
          justify-content: center;
          top: 9px;
          width: 100%;
          transition: opacity 1.5s;
        }
      }
    }
    ${theme.below.md} {
      &.index-1 > ul > li:nth-of-type(1),
      &.index-2 > ul > li:nth-of-type(2),
      &.index-3 > ul > li:nth-of-type(3),
      &.index-4 > ul > li:nth-of-type(4),
      &.index-5 > ul > li:nth-of-type(5) {
        opacity: 1;
      }
    }
  }
`;

const TopBarUsp = ({ data }) => {
  const { topBarUsp } = useContext(SiteContentContext);
  const outputData = data ? data : topBarUsp;
  const [index, setIndex] = useState(1);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      const nextIndex = index === 5 ? 1 : index + 1;
      setIndex(nextIndex);
    }, 4000);
    return () => clearInterval(interval);
  }, [index]);

  if (outputData)
    return (
      <TopBarUspWrapper className={`top-bar-usp-list index-${index}`}>
        {outputData}
      </TopBarUspWrapper>
    );
  return null;
};

export default TopBarUsp;
