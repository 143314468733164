// import t from '@jetshop/intl';
import React, { useContext } from 'react';
import { useAddwish } from '@jetshop/flight-addwish';
import { useQuery } from 'react-apollo';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { ProductCard } from '../../ProductCard';
import { styled } from 'linaria/react';

import MaxWidth from '../../../Layout-old/MaxWidth';
// import { theme } from '../../../Theme';
import cleanProps from '../../scripts/cleanProps';
import addwishProductQuery from './AddwishProductQuery.gql';
import Carousel from '../../../ui/Carousel';

const Wrapper = styled.section`
  button {
    background: rgba(255, 255, 255, 0.8) !important;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  button[data-direction='prev'] {
    left: 0;
  }
  button[data-direction='next'] {
    right: 0;
  }
`;

export const AddWish = ({ ids }) => {
  const {
    selectedChannel: { url },
  } = useContext(ChannelContext);

  const boxes = {};
  ids.forEach((id) => {
    boxes[id] = {
      url,
    };
  });
  const { addWishResponseBoxItems } = useAddwish({
    boxes,
    pageLoaded: true,
  });

  return ids.map((id) => {
    if ((addWishResponseBoxItems[id].loading = 'SUCCESS')) {
      return (
        <AddwishItems
          key={id}
          articleNumbers={addWishResponseBoxItems[id]?.items?.map(
            (item) => item.productNumber
          )}
        />
      );
    } else return null;
  });
};

const AddwishItems = ({ articleNumbers }) => {
  const { data, loading, error } = useQuery(addwishProductQuery, {
    variables: { articleNumbers },
    skip: articleNumbers.length === 0,
  });
  if (loading || error) {
    return null;
  }

  const products =
    data?.products.map((product) => {
      return {
        ...product,
        addwishData: data?.items?.find(
          (item) => item.productNumber === product.articleNumber
        ), // Make sure to include the addwishData to enable click tracking
      };
    }) || [];

  return <Carousel children={products} ProductCard={ProductCard} />;
};

const HelloRetail = (props) => {
  const propsCleaned = cleanProps(props);

  const ids = [propsCleaned?.helloRetailId ?? ''];

  return (
    <Wrapper>
      <MaxWidth removeMobilePadding={true} removeDesktopPadding={true}>
        <AddWish ids={ids} />
      </MaxWidth>
    </Wrapper>
  );
};

export default HelloRetail;
