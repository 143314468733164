import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import imageLink from '../ContentItems/imageLink';
import LinkItem from '../ContentItems/LinkItem';
import { ImageWithAspectRatio } from '../ContentItems/ImageWithAspectRatio';
import useChildren from '../hooks/useChildren';
import { Link } from 'react-router-dom';
import { AreaAuto } from '../Button';
import { ScrollBox } from '../ScrollBox';

const BrandsWrapper = styled('div')`
  margin-top: 0;
  margin-bottom: 0;
`;

const SectionWrapper = styled('div')`
  .slider-box-wrapper.no-scroll .slider-items {
    justify-content: center;
    .brand:last-child {
      margin-right: 0;
    }
  }
  //SLIDER WIDTH
  .slider-items {
    .brand {
      padding: 0 0.7rem;
      width: 23%;
      ${theme.only.sm} {
        width: 20%;
      }
      ${theme.only.md} {
        width: 15%;
      }
      ${theme.only.lg} {
        width: 12%;
      }
      ${theme.above.xl} {
        width: 9%;
      }
      ${theme.above.sm} {
        padding: 0 1.5rem;
      }
    }
  }
`;

const LinkStyled = styled(Link)`
  font-weight: bold;
  max-width: 100%;
  z-index: 2;
  text-decoration: underline;
  color: ${theme.colors.black};
  font-size: 1rem;
  line-height: 1.5rem;
  :hover {
    color: ${theme.colors.red};
  }
`;

const SizeWrapper = styled('div')`
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
`;

const Brands = (props) => {
  const allProps = useChildren(props);

  const imageLinks = allProps[imageLink];
  const links = allProps[LinkItem];

  let link = null;
  if (links?.length > 0) {
    const linkProps = links.pop();
    const { href, label } = linkProps;
    link = (
      <SectionWrapper style={{ marginTop: '2.5rem' }}>
        <LinkStyled to={href}>{label}</LinkStyled>
      </SectionWrapper>
    );
  }

  return (
    <BrandsWrapper>
      <SectionWrapper>
        <ScrollBox>
          {imageLinks.map((imageLinkProps) => {
            const { image, href, alt, key } = imageLinkProps;
            return (
              <SizeWrapper key={key} className="brand">
                <AreaAuto linkText={href}>
                  <ImageWithAspectRatio
                    src={image}
                    alt={alt}
                    aspect="1:1"
                    sizes={[100]}
                  />
                </AreaAuto>
              </SizeWrapper>
            );
          })}
        </ScrollBox>
      </SectionWrapper>
      {link}
    </BrandsWrapper>
  );
};

export default Brands;
