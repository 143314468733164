import React from 'react';
import cleanProps from '../scripts/cleanProps';

const SmallImageItem = (props) => {
  const propsCleaned = cleanProps(props);

  const { image, alt } = propsCleaned;

  return <img src={image} alt={alt} />;
};

export default SmallImageItem;
