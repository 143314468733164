import React from 'react';
import cleanProps from '../scripts/cleanProps';
import RichText from '../RichText';
import { styled } from 'linaria/react';
import MaxWidth from '../../Layout-old/MaxWidth';
import { theme } from '../../Theme';

const BlockWrapper = styled(MaxWidth)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled('div')`
  ${theme.above.lg} {
    width: 50%;
  }
`;

const Text = (props) => {
  const { html } = cleanProps(props);

  return (
    <BlockWrapper removeMobilePadding={false} removeDesktopPadding={false}>
      <TextWrapper>
        <RichText html={html} />
      </TextWrapper>
    </BlockWrapper>
  );
};

export default Text;
