import React from 'react';
import { styled } from 'linaria/react';
import cleanProps from '../scripts/cleanProps';
import { theme } from '../../Theme';

const CenteredParagraphItem = (props) => {
  const propsCleaned = cleanProps(props);
  const { text } = propsCleaned;

  const Text = styled('div')`
    ${theme.fontStyles.text}
    text-align: center;
    color: ${theme.colors.sumSecondaryDarkGray}
    max-width: 33.75rem;
  `;

  return <Text>{text}</Text>;
};

export default CenteredParagraphItem;
