import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import cleanProps from '../scripts/cleanProps';
import { ButtonLink } from '../Button';
import MaxWidth from '../../Layout-old/MaxWidth';

const BannerWithIconTitleTextButtonWrapper = styled('section')`
  width: 100%;
  ${theme.above.lg} {
    height: 6rem;
  }
  ${theme.below.lg} {
    height: 17.5rem;
  }
  background-color: ${theme.colors.sumSecondaryLightGray};
`;

const TextBlock = styled('div')`
  display: flex;
  flex-direction: column;

  ${theme.below.lg} {
    text-align: center;
    margin-bottom: 1rem;
  }
  ${theme.above.lg} {
    margin-right: 2.5rem;
  }

  h2 {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

const BannerWithIconTitleTextButton = (props) => {
  if (!props.children) return null;

  let data = {};
  for (let i = 0; i < props.children.length; i += 1) {
    const item = props.children[i];
    data = {
      ...data,
      ...cleanProps(item.props),
    };
  }

  const Image = styled('img')`
    ${theme.below.lg} {
      margin-bottom: 0.5rem;
      width: 5.125rem;
      height: 5.125rem;
    }
    ${theme.above.lg} {
      margin-right: 1rem;
    }
  `;

  const CenterWrapper = styled(MaxWidth)`
    display: flex;
    flex-direction: row;
    ${theme.below.lg} {
      justify-content: center;
    }
    ${theme.above.lg} {
      justify-content: flex-start;
    }
    align-items: center;
    width: 100%;
    height: 100%;
  `;

  const ContentWrapper = styled('div')`
    display: flex;
    ${theme.above.lg} {
      flex-direction: row;
      justify-content: flex-start;
      margin-left: 5rem;
    }
    ${theme.below.lg} {
      flex-direction: column;
      justify-content: center;
    }
    align-items: center;
  `;

  return (
    <BannerWithIconTitleTextButtonWrapper>
      <CenterWrapper removeMobilePadding={true} removeDesktopPadding={true}>
        <ContentWrapper>
          <Image src={data.image} alt="" />
          <TextBlock>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </TextBlock>
          <ButtonLink to={data.to}>{data.label}</ButtonLink>
        </ContentWrapper>
      </CenterWrapper>
    </BannerWithIconTitleTextButtonWrapper>
  );
};

export default BannerWithIconTitleTextButton;
