import { useMemo } from 'react';
const useCustomFields = (product) => {
  return useMemo(() => {
    if (!product) return {};
    const { customFields } = product;
    if (!customFields) return {};
    const newCustomFields = {};
    for (let i = 0; i < customFields.length; i += 1) {
      const customField = customFields[i];
      switch (customField.type) {
        case 'BOOL':
          newCustomFields[customField.key] = {
            value: customField.boolValue,
            title: customField.title,
          };
          break;

        case 'STRING':
          newCustomFields[customField.key] = {
            value: customField.stringValue,
            title: customField.title,
          };
          break;

        case 'HTML':
          newCustomFields[customField.key] = {
            value: customField.htmlValue,
            title: customField.title,
          };
          break;

        case 'LIST':
          newCustomFields[customField.key] = {
            value: customField.listValues,
            title: customField.title,
          };
          break;

        default:
          break;
      }
    }
    return newCustomFields;
  }, [product]);
};
export default useCustomFields;
