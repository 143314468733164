import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import cleanProps from '../scripts/cleanProps';
import TryggEhandel from '../../Layout/Footer/TryggEhandel';

const Text = styled('div')`
  color: ${theme.colors.white};
  font-size: 0.625rem;
  margin-right: 1rem;
  ${theme.below.lg} {
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
`;

const BannerPaymentInformationWrapper = styled('section')`
  width: 100%;
  background-color: ${theme.colors.black};
`;

const InnerWrapper = styled('div')`
  max-width: ${theme.maxWidths.wide};

  height: 2.5rem;
  margin: 0 auto;
  display: flex;
  ${theme.above.lg} {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  ${theme.below.wide} {
    padding: 0 2.5rem;
  }

  ${theme.below.lg} {
    height: 8rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
`;

const WrapperIcons = styled('div')`
  display: flex;
  align-items: center;
  > div {
    margin-right: 1.5rem;
    > a {
      width: 20px;
      display: flex !important;
      align-items: center;
      > img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
  img {
    margin-right: 1.5rem;
    :last-child {
      margin-right: 0rem;
    }
  }
`;

const BannerPaymentInformation = (props) => {
  if (!props.children) return null;
  const propsCleaned = cleanProps(props);

  return (
    <BannerPaymentInformationWrapper>
      <InnerWrapper>
        <Text>{propsCleaned.text}</Text>
        <WrapperIcons>
          <TryggEhandel />
          {props.children.map((item) => {
            const itemPropsCleaned = cleanProps(item.props);
            return (
              <img
                src={itemPropsCleaned.image}
                alt="En betalningsmetod"
                key={item.key}
              />
            );
          })}
        </WrapperIcons>
      </InnerWrapper>
    </BannerPaymentInformationWrapper>
  );
};

export default BannerPaymentInformation;
