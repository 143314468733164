import { ContentRenderer } from './ContentRenderer';
import React from 'react';
import { useQuery } from 'react-apollo';
import categoryByIdQuery from './gql/CategoryById.gql';

const CategoryComponents = (props) => {
  const { categoryPageId } = props;

  const { data, loading } = useQuery(categoryByIdQuery, {
    variables: { id: categoryPageId },
  });

  if (loading) return null;
  return <ContentRenderer items={data?.category?.data?.items ?? []} />;
};

export default CategoryComponents;
