import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { theme } from '../../../Theme';

import { ReactComponent as EmailIcon } from '../../../../svg/email.svg';
import { ReactComponent as PhoneIcon } from '../../../../svg/headset.svg';

import SocialLinks from './SocialLinks';

const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
`;

const Text = styled('a')`
  font-size: 1rem;
  height: 1.625rem;
  line-height: 2rem;
  width: 11rem;
  color: ${theme.colors.almostBlack};
`;

const CenterSpace = styled('div')`
  width: 0.25rem;
`;

export const Email = ({ email }) => {
  if (!email) return null;

  const EmailWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `;

  const StyledEmailIcon = styled(EmailIcon)`
    width: 1rem;
    height: 0.8125rem;
  `;

  return (
    <EmailWrapper>
      <IconWrapper>
        <StyledEmailIcon />
      </IconWrapper>
      <CenterSpace />
      <Text href={`mailto:${email}`}>{email}</Text>
    </EmailWrapper>
  );
};

export const Phone = ({ phone }) => {
  if (!phone) return null;

  const PhoneWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${theme.below.xl} {
      margin-bottom: 3.125rem;
    }
    ${theme.above.xl} {
      margin-bottom: 0.875rem;
    }
  `;

  const StyledPhoneIcon = styled(PhoneIcon)`
    width: 1.1875rem;
    height: 1.25rem;
  `;

  return (
    <PhoneWrapper>
      <IconWrapper>
        <StyledPhoneIcon />
      </IconWrapper>
      <CenterSpace />
      <Text href={`tel:${phone}`}>{phone}</Text>
    </PhoneWrapper>
  );
};

export const SocialMedia = ({ socialMediaLinks }) => {
  if (!socialMediaLinks) return null;

  const FollowUs = styled('span')`
    ${theme.below.xl} {
      font-size: 1rem;
      line-height: 1.625;
      margin-right: 0.875rem;
    }
    ${theme.above.xl} {
      font-size: 0.75rem;
      line-height: 1.625;
      margin-bottom: 0.25rem;
    }
  `;

  const SocialMediaWrapper = styled('div')`
    display: flex;
    ${theme.below.xl} {
      flex-direction: row;
      align-items: center;
    }
    ${theme.above.xl} {
      flex-direction: column;
    }

    p {
      margin-right: 1rem;
    }
  `;

  return (
    <SocialMediaWrapper>
      <FollowUs>{t('Follow us')}</FollowUs>
      <SocialLinks data={socialMediaLinks} />
    </SocialMediaWrapper>
  );
};
