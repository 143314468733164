import { styled } from 'linaria/react';
import MaxWidth from '../../../Layout-old/MaxWidth';
import { theme } from '../../../Theming/Theming';

const PromotionalBlockWrapper = styled(MaxWidth)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0.2rem;
  margin-bottom: 0.2rem;
  ${theme.above.md} {
    gap: 1rem;
    margin-bottom: 1rem;
  }
  background-color: ${theme.colors.white};
`;

const style = {
  PromotionalBlockWrapper,
};

export default style;
