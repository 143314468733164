const fonts = {
  primary: 'Brandon Text Web',
  highlight: 'Playfair Display',
  highlightBlack: 'Playfair Display Black',
};

module.exports = {
  default: {
    colors: {
      white: '#FFFFFF' /* White */,
      black: '#000000',
      almostBlack: '#0A0A0A',
      main: '#000000',
      grey: '#878787',
      darkerGrey: '#767676',
      lightgrey: '#E8E8E8',
      tablegrey: '#F3F3F3',
      beige: '#f5f5dc',
      loadingBar: '#2f80ed',
      blue: '#146DE1',
      red: '#DF0914' /* SUM Primary Red */,
      background: '#FFFFFF',
      darkgrey: '#333',
      mediumgrey: '#9a9a9a',
      highlight: '#2f80ed',
      yellow: '#FFFF00',

      sumPrimaryRed: '#DF0914' /* SUM Primary Red */,
      sumPrimaryBlack: '#000000' /* SUM Primary Black */,
      sumSecondaryDarkGray: '#4A4D4F' /* SUM Secondary Dark Gray */,
      sumSecondaryGray: '#C4C5C5' /* SUM Secondary Gray */,
      sumSecondaryLightGray: '#EAEAEB' /* SUM Secondary Light Gray */,

      filteringBackground: 'rgb(234, 234, 235, 0.2)',
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700,
      bold: 700,
    },
    breakpoints: {
      xs: '20rem',
      sm: '40rem',
      md: '50rem',
      lg: '64rem',
      xl: '80rem',
      wide: '105rem',
    },
    maxWidths: {
      default: '97.5rem',
      wide: '105rem',
    },
    fontSizes: [
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
      '48px',
      '64px',
      '72px',
    ],
    space: [
      '0px',
      '8px',
      '16px',
      '24px',
      '32px',
      '40px',
      '48px',
      '56px',
      '64px',
    ],
    fonts: fonts,
    fontStyles: {
      title: {
        'font-family': fonts.highlight,
        'font-weight': '700',
        'font-size': '1.75rem',
      },
      heroTitle: {
        'font-family': fonts.highlight,
        'font-size': '2.625rem', // 42px
        'line-height': '3.75rem',
        'letter-spacing': '0.05em',
      },
      promotionTitle: {
        'font-family': fonts.primary,
        'font-style': 'normal',
        'font-weight': 'bold',
        'font-size': '0.875rem',
        'line-height': '1.5rem',
        'letter-spacing': '0.125rem',
      },
      text: {
        'font-family': fonts.primary,
        'font-style': 'normal',
        'font-weight': 'normal',
        'font-size': '0.875rem', // 14px
        'line-height': '1.25rem', // 20px
        'letter-spacing': '0.05em',
      },
      storeLocator: {
        alphabet: {
          'font-family': fonts.primary,
          'font-style': 'normal',
          'font-weight': 'normal',
          'font-size': '1.25rem', // 20px
          'line-height': '1.75rem', // 28px
          'letter-spacing': '1px',
        },
        cardTitle: {
          'font-family': fonts.primary,
          'font-style': 'normal',
          'font-weight': 'bold',
          'font-size': '1.25rem', // 20px
          'line-height': '1.75rem', // 28px
          'letter-spacing': '1px',
        },
        cardSubTitle: {
          'font-family': fonts.primary,
          'font-style': 'normal',
          'font-weight': 'bold',
          'font-size': '0.875rem', // 14px
          'line-height': '0.875rem', // 14px
          'letter-spacing': '1px',
        },
        cardText14: {
          'font-family': fonts.primary,
          'font-style': 'normal',
          'font-weight': 'normal',
          'font-size': '0.875rem', // 14px
          'line-height': '1.25rem', // 20px
          'letter-spacing': '1px',
        },
        cardText12: {
          'font-family': fonts.primary,
          'font-style': 'normal',
          'font-weight': 'normal',
          'font-size': '0.75rem', // 12px
          'line-height': '1.0625rem', // 17px
          'letter-spacing': '1px',
        },
      },
      store: {
        title: {
          'font-family': fonts.primary,
          'font-style': 'normal',
          'font-weight': 'bold',
          'font-size': '2rem', // 32px
          'line-height': '2.8125rem', // 46px
          'letter-spacing': '1px',
        },
        text: {
          'font-family': fonts.primary,
          'font-style': 'normal',
          'font-weight': 'normal',
          'font-size': '1rem', // 16px
          'line-height': '1.375rem', // 22px
          'letter-spacing': '1px',
        },
      },
      product: {
        name: {
          'font-family': fonts.primary,
          'font-style': 'normal',
          'font-weight': 'bold',
          'font-size': '1.25rem',
          'line-height': '1.5rem',
          'letter-spacing': '0.05em',
        },
        brand: {
          'font-family': fonts.primary,
          'font-style': 'normal',
          'font-weight': 'bold',
          'font-size': '1rem',
          'line-height': '1.5rem',
          'letter-spacing': '0.05em',
        },
        type: {
          'font-family': fonts.primary,
          'font-style': 'normal',
          'font-weight': 'normal',
          'font-size': '0.875rem',
          'line-height': '1.5rem',
          'letter-spacing': '0.05em',
        },
        price: {
          'font-family': fonts.primary,
          'font-style': 'normal',
          'font-weight': 'bold',
          'font-size': '1.5rem',
          'line-height': '1.5rem',
          'letter-spacing': '0.05em',
        },
      },
    },
  },
  alt: {
    colors: {
      blue: 'blue',
      main: 'black',
    },
  },
};
