import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons';

const UserIconRound = ({ className = '', ...rest }) => {
  return (
    <FontAwesomeIcon icon={faUserCircle} {...rest} className={className} />
  );
};
export default UserIconRound;
