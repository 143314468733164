import { ContentRenderer as Renderer } from '@jetshop/ui/ContentRenderer';
import React from 'react';
import { styled } from 'linaria/react';

// Items
import ButtonItem from './ContentItems/ButtonItem';
import ImageItem from './ContentItems/ImageItem';
import ImageTitleItem from './ContentItems/ImageTitleItem';
import ImageTitleTextItem from './ContentItems/ImageTitleTextItem';
import LargeImageItem from './ContentItems/LargeImageItem';
import LinkItem from './ContentItems/LinkItem';
import ParagraphItem from './ContentItems/ParagraphItem';
import SentenceItem from './ContentItems/SentenceItem';
import SmallImageItem from './ContentItems/SmallImageItem';
import TitleItem from './ContentItems/TitleItem';
import ImageButtonItem from './ContentItems/ImageButtonItem';
import CenteredParagraphItem from './ContentItems/CenteredParagraphItem';
import PromotionalItem from './ContentItems/PromotionalItem/PromotionalItem';
import Information from './ContentItems/NavigationLink/Information';
import Assortment from './ContentItems/NavigationLink/Assortment';
import Brand from './ContentItems/NavigationLink/Brand';
import SocialMedia from './ContentItems/NavigationLink/SocialMedia';
import ImageWithAspectRatio from './ContentItems/ImageWithAspectRatio';
import imageLink from './ContentItems/imageLink';
import TextColor from './ContentItems/TextColor';

// Blocks
import BannerWithImageTitleItemsBlock from './ContentBlocks/BannerWithImageTitleItemsBlock';
import CenteredImageBlock from './ContentBlocks/CenteredImageBlock';
import HeroBlock from './ContentBlocks/HeroBlock';
import TitleBlock from './ContentBlocks/TitleBlock';
import FlowboxBlock from './ContentBlocks/FlowboxBlock';
import CenteredTextBlock from './ContentBlocks/CenteredTextBlock';
import PromotionalBlock from './ContentBlocks/PromotionalBlock/PromotionalBlock';
import Footer from './ContentBlocks/Footer/Footer';
import BannerWithIconTitleTextButton from './ContentBlocks/BannerWithIconTitleTextButton';
import BannerPaymentInformation from './ContentBlocks/BannerPaymentInformation';
import HelloRetail from './ContentBlocks/HelloRetail/HelloRetail';
import TextAndImage from './ContentBlocks/TextAndImage';
import Text from './ContentBlocks/Text';
import Store from './ContentBlocks/Store';
import Brands from './ContentBlocks/Brands';
import Form from './ContentBlocks/Form';
import CategoryHero from './ContentBlocks/CategoryHero';
import CardGrid from './ContentBlocks/CardGrid';
import Products from './ContentBlocks/Products';
import Buttons from './ContentBlocks/Buttons';

// Component elements should use a camel case name,
// the name will be in capital letters when we get it.
// imageWithAspectRatio -> IMAGEWITHASPECTRATIO

// Root elements should use words that are easy for the client
// to understand and they don't need to end with block.
// The name will be in capital letters with the spaces kept.
// Text And Image -> 'TEXT AND IMAGE'
export const Components = {
  BUTTONITEM: ButtonItem,
  IMAGEITEM: ImageItem,
  IMAGETITLEITEM: ImageTitleItem,
  IMAGETITLETEXTITEM: ImageTitleTextItem,
  LARGEIMAGEITEM: LargeImageItem,
  LINKITEM: LinkItem,
  PARAGRAPHITEM: ParagraphItem,
  SENTENCEITEM: SentenceItem,
  SMALLIMAGEITEM: SmallImageItem,
  TITLEITEM: TitleItem,
  IMAGEBUTTONITEM: ImageButtonItem,
  BANNERWITHIMAGETITLEITEMSBLOCK: BannerWithImageTitleItemsBlock,
  CENTEREDIMAGEBLOCK: CenteredImageBlock,
  HEROBLOCK: HeroBlock,
  TITLEBLOCK: TitleBlock,
  FLOWBOXBLOCK: FlowboxBlock,
  CENTEREDTEXTBLOCK: CenteredTextBlock,
  CENTEREDPARAGRAPHITEM: CenteredParagraphItem,
  PROMOTIONALITEM: PromotionalItem,
  PROMOTIONALBLOCK: PromotionalBlock,
  'BANNER WITH ICON TITLE TEXT BUTTON': BannerWithIconTitleTextButton,
  'SITE FOOTER': Footer,
  'NAVIGATION LINK - INFORMATION': Information,
  'NAVIGATION LINK - ASSORTMENT': Assortment,
  'NAVIGATION LINK - BRAND': Brand,
  'NAVIGATION LINK - SOCIALMEDIA': SocialMedia,
  'BANNER PAYMENT INFORMATION': BannerPaymentInformation,
  'HELLO RETAIL': HelloRetail,
  IMAGEWITHASPECTRATIO: ImageWithAspectRatio,
  'TEXT AND IMAGE': TextAndImage,
  TEXT: Text,
  STORE: Store,
  BRANDS: Brands,
  FORM: Form,
  IMAGELINK: imageLink,
  'CATEGORY HERO': CategoryHero,
  'CARD GRID': CardGrid,
  PRODUCTS: Products,
  TEXTCOLOR: TextColor,
  BUTTONS: Buttons,
};

const ContentRendererWrapper = styled('section')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentRenderer = (props) => {
  let { items } = props;

  if (!items) items = [];

  return (
    <ContentRendererWrapper>
      <Renderer items={items} components={Components} />
    </ContentRendererWrapper>
  );
};
