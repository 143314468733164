import React from 'react';
import cleanProps from '../scripts/cleanProps';

const SentenceItem = (props) => {
  const propsCleaned = cleanProps(props);

  const { text } = propsCleaned;

  return <p>{text}</p>;
};

export default SentenceItem;
