import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { ReactComponent as Background } from '../../../svg/customer/logo-symbol.svg';
import MaxWidth from '../../Layout-old/MaxWidth';
import useChildren from '../hooks/useChildren';

const TitleBlockWrapper = styled(MaxWidth)`
  position: relative;
  display: flex;
  justify-content: center;

  background-color: ${theme.colors.white};

  margin-top: 7rem;
  margin-bottom: 1.75rem;

  ${theme.below.md} {
    margin-top: 4.25rem;
  }
`;

const CenterMe = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const BackgroundStyled = styled(Background)`
  opacity: 0.05;
  position: absolute;
  text-align: center;
  z-index: 2;
  fill: ${theme.colors.black};
`;

const Title = styled('div')`
  ${theme.fontStyles.title}
  text-align: center;
`;

const TitleBlock = (props) => {
  let title;
  const allProps = useChildren(props);
  if (props.title) {
    title = props.title;
  } else {
    const titleItems = allProps['TitleItem'];
    if (!titleItems || titleItems.length === 0) return null;
    const titleItem = titleItems.pop();
    title = titleItem.title;
  }

  return (
    <TitleBlockWrapper removeMobilePadding={true} removeDesktopPadding={true}>
      <CenterMe>
        <BackgroundStyled />
        <Title>{title}</Title>
      </CenterMe>
    </TitleBlockWrapper>
  );
};

export default TitleBlock;
