import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../../Layout-old/MaxWidth';
import cleanProps from '../scripts/cleanProps';
import { initFlowbox, initFlow } from '../../ui/scripts/flowbox';

const FlowboxBlock = (props) => {
  const propsCleaned = cleanProps(props);

  let { flowId, flowKey, flowLocale } = propsCleaned;

  if (!flowId) flowId = flowKey;
  if (!flowLocale) flowLocale = 'sv-SE';

  useEffect(() => {
    initFlowbox()
      .then((message) => {
        initFlow(flowId, flowKey, flowLocale);
      })
      .catch((message) => {
        console.error(message);
      });
  }, [flowId, flowKey, flowLocale]);

  const FlowboxBlockWrapper = styled(MaxWidth)``;

  return (
    <FlowboxBlockWrapper removeMobilePadding={true} removeDesktopPadding={true}>
      <div id={flowId}></div>
    </FlowboxBlockWrapper>
  );
};

export default FlowboxBlock;
