import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import storeBlockQuery from '../gql/StoreById.gql';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import cleanProps from '../scripts/cleanProps';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import options from '../GoogleMap/options';
import MaxWidth from '../../Layout-old/MaxWidth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import throwErrorInDev from '@jetshop/core/helpers/throwErrorInDev';

const StoreBlockWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 2rem;
  ${theme.above.md} {
    flex-direction: row;
    padding: 2rem 1.5rem;
  }
`;

const StoreBlockInnerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  ${theme.above.md} {
    margin: 0 0.5rem 0 6rem;
    align-items: flex-start;
  }
  ${theme.above.xl} {
    margin-left: 8.875rem;
  }
`;

const TextWrapper = styled('div')`
  ${theme.below.md} {
    width: fit-content;
    margin-bottom: 3rem;
  }
`;

const MapWrapper = styled('div')`
  position: relative;
  ${theme.above.lg} {
    width: 70%;
    height: 100%;
    min-height: 520px;
  }

  ${theme.below.lg} {
    ${theme.above.md} {
      width: 50%;
      height: 100%;
      min-height: 520px;
    }

    ${theme.below.md} {
      width: 100%;
      height: 400px;
    }
  }
`;

const TextTitle = styled('div')`
  ${theme.fontStyles.store.title};
  margin: auto auto 0.75rem auto;
  text-align: center;
  ${theme.above.md} {
    text-align: left;
  }
`;

const Text = styled('div')`
  > p {
    ${theme.fontStyles.store.text};
    margin: auto;
    box-sizing: border-box;
    text-align: center;
    ${theme.above.md} {
      text-align: left;
    }
    > a {
      text-decoration: none;
      color: ${theme.colors.red};
      :hover {
        text-decoration: underline;
      }
    }
  }
  > ol,
  > ul {
    display: flex;
    flex-direction: column;
    list-style: inside;
    width: fit-content;
    margin: auto;
    box-sizing: border-box;
    ${theme.fontStyles.text};
  }
  > ol {
    list-style-type: decimal;
  }
  > ul {
    list-style-type: disc;
  }
`;

const InfoWindowContents = styled('div')`
  overflow: auto;
  background-color: white;
  padding: 1em;
  min-width: 200px;
  font-size: 14px;
  line-height: 1.5;
`;

const StoreName = styled('div')`
  font-weight: bold;
`;

const StoreAddress = styled('div')``;

const MapContainer = (props) => {
  const { google, store, loaded } = props;

  if (!loaded) return null;

  const center = {
    lat: store?.coordinates?.latitude,
    lng: store?.coordinates?.longitude,
  };

  return (
    <Map
      google={google}
      zoom={9}
      initialCenter={center}
      onReady={(mapProps, map) => {
        map.setOptions(options);
      }}
    >
      <Marker
        position={{
          lat: store?.coordinates?.latitude,
          lng: store?.coordinates?.longitude,
        }}
        icon={{
          path: 'M12.2538 26.8666C12.1103 26.9985 11.8897 26.9985 11.7462 26.8666C6.91539 22.4258 4.5 18.247 4.5 14.3301C4.5 10.2818 7.85786 7 12 7C16.1421 7 19.5 10.2818 19.5 14.3301C19.5 18.247 17.0846 22.4258 12.2538 26.8666ZM12 18C13.933 18 15.5 16.433 15.5 14.5C15.5 12.567 13.933 11 12 11C10.067 11 8.5 12.567 8.5 14.5C8.5 16.433 10.067 18 12 18Z',
          fillColor: theme.colors.red,
          fillOpacity: 1,
          strokeColor: '#656565',
          strokeOpacity: 1,
          anchor: new google.maps.Point(12, 32),
          scale: 1.25,
          labelOrigin: new google.maps.Point(12, 14),
        }}
        clickable={false}
      >
        <InfoWindow>
          <InfoWindowContents>
            <StoreName>{store.name}</StoreName>
            <StoreAddress
              dangerouslySetInnerHTML={{
                __html: store.address1,
              }}
            />
          </InfoWindowContents>
        </InfoWindow>
      </Marker>
    </Map>
  );
};

const StoreMap = GoogleApiWrapper((props) => ({
  apiKey: props.googleMapsApiKey,
}))(MapContainer);

const Store = (props) => {
  const propsCleaned = cleanProps(props);
  const { googleMapsApiKey } = useShopConfig();

  throwErrorInDev(
    typeof googleMapsApiKey === 'undefined',
    'Make sure googleMapsApiKey is defined in your shop.config.js. See https://developers.google.com/maps/documentation/javascript/get-api-key'
  );

  let storeId = 0;
  try {
    storeId = parseInt(propsCleaned.storeId);
  } catch (e) {
    console.error(
      'Store id could not be parsed! Check the Store block in admin!'
    );
  }

  const queryResult = useQuery(storeBlockQuery, {
    variables: { storeId: storeId },
  });

  if (!queryResult || !queryResult.data || !queryResult.data.store) return null;
  const { address1, openHours, contact } = queryResult.data.store;

  return (
    <MaxWidth removeDesktopPadding={true}>
      <StoreBlockWrapper>
        <StoreBlockInnerWrapper>
          {address1 ? (
            <TextWrapper>
              <TextTitle>Adress</TextTitle>
              <Text dangerouslySetInnerHTML={{ __html: address1 }} />
            </TextWrapper>
          ) : null}
          {openHours ? (
            <TextWrapper>
              <TextTitle>Öppettider</TextTitle>
              <Text dangerouslySetInnerHTML={{ __html: openHours }} />
            </TextWrapper>
          ) : null}
          {contact ? (
            <TextWrapper>
              <TextTitle>Kontakt</TextTitle>
              <Text dangerouslySetInnerHTML={{ __html: contact }} />
            </TextWrapper>
          ) : null}
        </StoreBlockInnerWrapper>
        <MapWrapper>
          <StoreMap
            store={queryResult.data.store}
            googleMapsApiKey={googleMapsApiKey}
          />
        </MapWrapper>
      </StoreBlockWrapper>
    </MaxWidth>
  );
};

export default Store;
