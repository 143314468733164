export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favourites',
    },
    blog: {
      path: process.env.REACT_APP_BLOG_PATH || '/blogg',
      id: process.env.REACT_APP_BLOG_ID || 218,
      mainLevel: 1,
      menu: true,
    },
    brand: {
      id: 156,
    },
    category: {
      hide: false,
    },
    product: {
      hide: false,
    },
  },
  settings: {
    storeName: 'Stjarnurmakarna',
    siteContentId: {
      Default: 125,
      B2B: 131,
    },
  },
  sizeGuide: {
    skipOnCategory: {
      smycken: true,
      tillbehor: true,
    },
  },
};
