const cleanProps = (props) => {
  // Removes the layers of value from the props
  // This returns a new changed props

  const objectMap = (obj, fn) =>
    Object.fromEntries(
      Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
    );

  return objectMap(props, (value) => {
    if (value === null) return null;
    if (typeof value === 'object' && 'value' in value) {
      if (value.value === null) return null;
      if (typeof value.value === 'object' && 'value' in value.value) {
        return value.value.value;
      }
      return value.value;
    }
    return value;
  });
};

export default cleanProps;
