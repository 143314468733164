import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

export const Text = styled('div')`
  h1 {
    font-family: 'Playfair Display';
    font-weight: normal;
    font-size: 1.75rem;
    margin: 0rem;
    margin-bottom: 0.75rem;
  }
  h2 {
    font-size: 1.75rem;
    font-weight: bold;
    margin: 0rem;
    margin-bottom: 0.75rem;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0rem;
    margin-bottom: 0.75rem;
  }
  ,
  h4 {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
    margin-bottom: 0.75rem;
  }
  ,
  h5 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    margin-bottom: 0.75rem;
  }
  ,
  h6 {
    font-size: 0.875rem;
    font-weight: bold;
    margin: 0;
    margin-bottom: 0.75rem;
  }
  p {
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
    margin-bottom: 0.75rem;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  u {
    text-decoration: underline;
  }
  blockquote {
    font-size: 1.1rem;
    font-weight: normal;
    font-style: italic;
    border-left: 5px solid #eee;
    margin: 1rem 0rem;
    padding: 0.625rem 1.25rem;
  }
  pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: monospace;
    font-size: 1rem;
    font-weight: normal;
    padding: 1.25rem;
    overflow: auto;
  }
  ol,
  ul {
    font-size: 1rem;
    font-weight: normal;
    list-style: inside;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
  li {
  }
  a {
    font-size: 1rem;
    font-weight: normal;
    text-decoration: underline;
    color: ${theme.colors.red};
    margin-bottom: 0.75rem;
  }
`;

export const RichText = ({ html }) => {
  return <Text dangerouslySetInnerHTML={{ __html: html }} />;
};

export default RichText;
