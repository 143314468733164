import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import MaxWidth from '../../Layout-old/MaxWidth';
import CenteredParagraphItem from '../ContentItems/CenteredParagraphItem';

const CenteredTextBlockWrapper = styled(MaxWidth)`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${theme.colors.white};
  padding-bottom: 3rem;
`;

const CenteredTextBlock = (props) => {
  if (!props.children) return null;

  return (
    <CenteredTextBlockWrapper
      removeMobilePadding={false}
      removeDesktopPadding={true}
    >
      <CenteredParagraphItem {...props.children[0].props} />
    </CenteredTextBlockWrapper>
  );
};

export default CenteredTextBlock;
