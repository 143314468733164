import React from 'react';
import { ReactComponent as LogoMobile } from '../../../svg/customer/stjarnurmakarna-logo-mobile.svg';
import { ReactComponent as LogoDesktop } from '../../../svg/customer/stjarnurmakarna-logo.svg';
import { ReactComponent as LogoSymbol } from '../../../svg/customer/logo-symbol.svg';

const Logo = ({ type = false }) => {
  if (type === 'mobile') return <LogoMobile />;
  else if (type === 'symbol') return <LogoSymbol />;
  return <LogoDesktop />;
};

export default Logo;
