import { styled } from 'linaria/react';
import { theme } from '../Theme';

/**
 * This splits the children of this element into equal part sections
 */
export default styled('section')`
  display: flex;

  ${theme.above.xl} {
    > * {
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  ${theme.below.xl} {
    flex-direction: column;
  }
`;
