import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import PagePreviewQuery from './PagePreviewQuery.gql';
import Container from './Layout-old/Container';
import Content from './Layout/Content';
import Footer from './Layout-old/Footer';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import { theme } from './Theme';
import '../globalStyles';
import HeaderComponent from './Layout/Header/Header';
import SiteContent from './SiteContent/SiteContentProvider';
import ProductPage from './ProductPage/ProductPage.loadable';
import CategoryPage from './CategoryPage/CategoryPage.loadable';
import ContentPage from './ContentPage/ContentPage.loadable';
import SiteContentContext from './SiteContent/SiteContentContext';
import BlogRoute from './Blog/BlogRoute';

const Store = loadable(() => import('./Store/Store'), {
  fallback: <LoadingPage />,
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />,
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />,
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />,
});

const LogOutPage = loadable(
  () => import('@jetshop/core/components/AuthContext/LogOut'),
  {
    fallback: <LoadingPage />,
  }
);

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />,
  }
);

const NavTreePage = loadable(() => import('./NavigationTree/NavTreePage'), {
  fallback: <LoadingPage />,
});

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />,
});

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />,
});
const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />,
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />,
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />,
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />,
});

const Routes = () => {
  const { routes } = useShopConfig();
  const { blogs } = useContext(SiteContentContext);
  return (
    <Switch>
      {blogs &&
        blogs.map((blog, index) => {
          const url = blog?.category?.value?.primaryRoute?.path;
          return (
            <Route
              key={index}
              exact
              path={
                blog.menu.value === 'yes'
                  ? [`${url}/:cat/:item`, `${url}/:cat`, url]
                  : [`${url}/:item`, url]
              }
            >
              <BlogRoute blog={blog} />
            </Route>
          );
        })}
      <Route exact path="/">
        <LoadableStartPage />
      </Route>
      <Route path="/favourites">
        <Favourites />
      </Route>
      <Route path={routes.search.path}>
        <LoadableSearchPage />
      </Route>
      <Route path={routes.signup.path}>
        <LoadableSignUpPage />
      </Route>
      <Route path={routes.login.path}>
        <LogInPage />
      </Route>
      <Route path={routes.logout.path}>
        <LogOutPage />
      </Route>
      <Route path={routes.stores.path} exact>
        <StoreLocator />
      </Route>
      <Route path={`${routes.store.path}/:id`}>
        <Store />
      </Route>
      <Route path={routes.tree.path}>
        <NavTreePage />
      </Route>
      <Route path={routes.myPages.path}>
        <LoadableMyPages />
      </Route>
      <Route exact path={routes.forgotPassword.path}>
        <ForgotPassword />
      </Route>
      <Route path={`${routes.resetPassword.path}/:token`}>
        <ResetPassword />
      </Route>
      <Route
        path="/preview"
        render={(props) => (
          <PreviewRoute
            productQuery={ProductPreviewQuery}
            categoryQuery={CategoryPreviewQuery}
            pageQuery={PagePreviewQuery}
            productPage={ProductPage}
            categoryPage={CategoryPage}
            contentPage={ContentPage}
            StartPage={LoadableStartPage}
            {...props}
          />
        )}
      />
      <DynamicRoute
        routeQuery={routeQuery}
        productPage={ProductPage}
        categoryPage={CategoryPage}
        contentPage={ContentPage}
        notFoundPage={NotFound}
        LoadingPage={LoadingPage}
      />
    </Switch>
  );
};

function Shop() {
  return (
    <GenericError>
      <ModalProvider>
        <SiteContent>
          <Container>
            <LoadingBar color={theme.colors.loadingBar} />
            <CustomFont
              primaryFont={theme.fonts.primary}
              injectCss={loadFontCss}
            />
            <Helmet
              titleTemplate="%s - Stjärnurmakarna"
              defaultTitle="Stjärnurmakarna"
            />
            <ProductListProvider queries={productListQueries}>
              <PaginationProvider defaultProductsPerPage={24}>
                <HeaderComponent />
                <Content>
                  <Routes />
                </Content>
                <Footer />
              </PaginationProvider>
            </ProductListProvider>
            <ModalRoot />
            <ScrollRestorationHandler
              ignoreForRouteTypes={[
                'sortOrderChange',
                'filterChange',
                'paginationChange',
              ]}
            />
          </Container>
        </SiteContent>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
